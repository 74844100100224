import React from "react"
import PropTypes from "prop-types"
import { css } from "emotion";

import Header from "../components/header"
import Footer from "../components/footer"
import '../assets/css/index.scss'

const Layout = ({ children, bgcolor }) => {

  const handleMove = (e) => {
    const cursor = document.querySelector(".cursor")
    cursor.style.left = `${e.clientX}px`
    cursor.style.top = `${e.clientY}px`
    cursor.style.opacity = "1"
  }

  const handleLeave = (e) => {
    const cursor = document.querySelector(".cursor")
    cursor.style.display = "none"
  }

  const handleEnter = (e) => {
    const cursor = document.querySelector(".cursor")
    cursor.style.display = ""
  }

  const handleDown = (e) => {
    const cursor = document.querySelector(".cursor")
    cursor.style.background = "#FFFFFF"
  }

  const handleUp = (e) => {
    const cursor = document.querySelector(".cursor")
    cursor.style.background = ""
  }

  const card = css`
    background: ${bgcolor};
    transition: background 600ms;
  `;

  return (
    <div
      role="presentation"
      className={`${card} container`}
      onMouseMove={handleMove} 
      onMouseLeave={handleLeave} 
      onMouseEnter={handleEnter}
      onMouseDown={handleDown} 
      onMouseUp={handleUp} 
    >
      <div className="cursor" />
      <Header />
      <main>
        {children}
      </main>
      <Footer />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
