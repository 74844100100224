import React from "react"

const Header = () => {
    return (
        <header>
            <h1>Diego Pavan</h1>
            <p>Martech &amp; Web designer</p>
            <p>Milan, IT</p>
        </header>
    )
}

export default Header
