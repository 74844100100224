import React from "react"

const Footer = () => {
    return (
        <footer>
            <p>Get in touch</p>
            <p><a href="mailto:hello@diegopavan.com" alt="Get in touch">hello@diegopavan.com</a></p>
        </footer>
    )
}

export default Footer
